import React from "react";
require.context("./images", true, /.*/);

//destructure the object coming in
export default function Portfolio({ myProjects }) {
  return (
    <section className="project-card-holder">
      {myProjects.map((project, index) => {
        return (
          <div className="flip-box" key={project.key}>
            <input
              type="checkbox"
              className="btnControl"
              id={"btnControl-" + project.key}
            />
            <label
              htmlFor={"btnControl-" + project.key}
              className="card flip-box-inner"
              id={`${index === 0 ? "top-project-card" : "project-card"}`}
            >
              <div
                className="flip-box-front"
                style={{
                  backgroundImage:
                    "url(" + require(`./images/${project.image}`) + ")",
                }}
              ></div>
              <figure className="flip-box-back">
                <h3>{project.name}</h3>
                <p>{project.description}</p>
                <div className="center-button">
                  <button
                    onClick={() => window.open(project.link)}
                    type="button"
                    class="btn btn-light flip-box-inner-button"
                  >
                    GO!
                  </button>
                </div>
              </figure>
            </label>
          </div>
        );
      })}
    </section>
  );
}
