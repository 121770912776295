import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

export default function Navigation() {
  return (
    <>
      <Navbar
        key="lg"
        expand="lg"
        className="bg-body-tertiary mb-3 willb-navbar"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Brand href="#">Welcome to Will</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            className=""
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                Welcome to Will
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-start flex-grow-1 pe-3">
                {[
                  ["About Me", "/about"],
                  ["Portfolio", "/portfolio"],
                  ["Contact Me", "/contact"],
                  ["Resume", "/resume"],
                ].map(([text, link]) => (
                  <Nav.Link
                    key={link}
                    className="border-start border-black border-opacity-10 border-2 py-5"
                    href={`#${link}`}
                  >
                    {text}
                  </Nav.Link>
                ))}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}
